import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import logo from '../images/driver.png';
import logo1 from '../images/user.png';
import logo2 from '../images/driver1.jpeg'
import logo3 from '../images/ride-label.png'

import '../App.css';


import { Link, useLocation } from "react-router-dom";


function HeaderCheck() {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation()
  let timer
  var [currentImage,
    setCurrentImage] = useState(0)
  //   var [showMenu,
  //       setShowMenu] = useState(false)
  var images = [logo, logo1, logo2, logo3]

  useEffect(() => {
    switchImage()
    return () => clearInterval(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function switchImage() {

    timer = !timer && setInterval(() => {

      setCurrentImage(prev => prev < images.length - 1
        ? prev + 1
        : 0)
    }, 2000)

  }





  return (
    <div>
      <nav className="bg-white-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">



                <button>
                  <span class="sr-only">Workflow</span>
                  <img class="object-scale-down sh-8 w-24 sm:h-10" alt='logo' src={images[currentImage]} /></button>


              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">


                  {
                    location.pathname === '/' ? <Link
                      class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/">Company</Link> :
                      <Link
                        class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/">Company</Link>
                  }

                  {
                    location.pathname === '/safety' ? <Link
                      class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/safety">Safety</Link> :
                      <Link
                        class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/safety">Safety</Link>
                  }

                  {
                    location.pathname === '/help' ? <Link
                      class=" hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/help">Help</Link> :
                      <Link
                        class=" hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/help">Help</Link>
                  }

                  {
                    location.pathname === '/driver' ? <Link
                      class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/driver">Driver</Link> :
                      <Link
                        class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/driver">Driver</Link>
                  }

                  {/* <a
                    href="#"
                    className=" hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Rider
                  </a> */}

                  {
                    location.pathname === '/rider' ? <Link
                      class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/rider">Rider</Link> :
                      <Link
                        class="hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/rider">Rider</Link>
                  }

                {
                    location.pathname === '/rider' ? <Link
                      class="w-24 bg-indigo-500 hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                      to="/login">Book Ride</Link> :
                      <Link
                        class="w-24 bg-indigo-500 hover:bg-gray-700 hover:text-white text-blue px-3 py-2 rounded-md text-sm font-medium"
                        to="/login">Book Ride</Link>
                  }


                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {/* <a
                  href="#"
                  className="hover:bg-gray-700 text-black block px-3 py-2 rounded-md text-base font-medium"
                >
                  Company
                </a> */}

                <Link
                  class="text-black-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/">Company</Link>

                {/* <a
                  href="#"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Safety
                </a> */}

                <Link
                  class="text-black-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/safety">Safety</Link>

                {/* <a
                  href="#"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Help
                </a> */}

                <Link
                  class="text-black-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/help">Help</Link>

                {/* <a
                  href="#"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Driver
                </a> */}

                <Link
                  class="text-black-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/driver">Driver</Link>

                {/* <a
                  href="#"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Rider
                </a> */}

                <Link
                  class="text-black-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/rider">Rider</Link>
                <Link
                  class="bg-indigo-500 text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  to="/login">Book Ride</Link>
              </div>
            </div>
          )}
        </Transition>
      </nav>

      <header className="bg-white shadow">

      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* <!-- Replace with your content --> */}

          {/* <!-- /End replace --> */}
        </div>
      </main>
    </div>
  );
}

export default HeaderCheck;