import React from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';
import Content from '../Content.json'
import Step from '../components/Step';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';



// import TouchCarousel from 'react-touch-carousel'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import { Carousel } from '@trendyol-js/react-carousel';
// import CarouselSlider from "react-carousel-slider";

// import CarouselPage from './Carousel';


// import {Switch, Route} from "react-router-dom";

// import InstantRide from './InstatnRide';

export default function Rider() {
    var pageContent = Content.rider

    // const [bgColour, setBgColour] = useState("#fafafa")
    // const [bgColour1, setBgColour1] = useState("#fafafa")

    // const [bgColour2, setBgColour2] = useState("#fafafa")




    return (
        <div>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="lg:w-4/5 mx-auto flex flex-wrap">
                        <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">

                            <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">
                                {pageContent.slogan1}
                                <br />
                                <span>
                                    {pageContent.slogan2}</span>
                                <br />
                                <span>
                                    {pageContent.slogan3}</span>
                            </h1>

                            {/* <a class="mt-3 text-indigo-500 inline-flex items-center">{pageContent.signup}
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a> */}
                        </div>
                        <img
                            alt="rider welcome"
                            class="lg:w-1/2 w-full lg:h-auto h-64 object-fill object-center rounded"
                            src={process.env.PUBLIC_URL + "/assets/riderPrimary.jpg"} />
                    </div>
                </div>
            </section>

            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-12">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{pageContent.requestRideHeading}</h1>
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">{pageContent.estimateNote}</p>
                    </div>
                    {/* <div
                        class="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
                        <div class="relative flex-grow w-full">
                            <label for="full-name" class="leading-7 text-sm text-gray-600">{pageContent.pickupPlaceholder}</label>
                            <input
                                type="text"
                                id="full-name"
                                name="full-name"
                                class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div class="relative flex-grow w-full">
                            <label for="email" class="leading-7 text-sm text-gray-600">{pageContent.dropPlaceholder}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>
                    <div
                        class="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-stretch p-3.5">
                        <button
                            class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg ">{pageContent.rideNow}</button>
                        <button
                            class="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg ">{pageContent.scheduleLater}</button>
                    </div> */}
                </div>
            </section>

            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="lg:w-4/5 mx-auto flex flex-wrap">
                        <img
                            alt="on demand"
                            class="lg:w-1/2 w-full lg:h-auto h-64 object-fill object-center rounded"
                            src={process.env.PUBLIC_URL + "/assets/ondemand.jpg"}  />
                        <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">

                            <h1 class="text-gray-900 text-3xl title-font font-medium mb-1" style={{ marginTop: '5%' }} >{pageContent.slogan4}</h1>

                            <p class="leading-relaxed" style={{ marginTop: '8%', fontWeight: 'bold' }} >{pageContent.slogan4Desc}</p>
                            <p class="leading-relaxed" style={{ marginTop: '3%', fontWeight: 'bold' }} >{pageContent.slogan4Desc2}</p>
                            <p class="leading-relaxed" style={{ marginTop: '3%', fontWeight: 'bold' }} >{pageContent.slogan4Desc3}</p>
                            <p class="leading-relaxed" style={{ marginTop: '3%', fontWeight: 'bold' }} >{pageContent.slogan4Desc4}</p>

                            <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5"></div>

                        </div>
                    </div>
                </div>
            </section>


            {/*why choose Gurway  */}

            <section className='text-gray-600 body-font' >

                <h1
                    class="space-x-2  text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl" style={{ textAlign: 'center' }} >
                    <span class="block text-indigo-600 xl:inline"  >{pageContent.whyGurwayRide}</span>

                </h1>

                <div style={{ display: 'flex', marginLeft: 0, marginTop: '5%' }} >

                    <Flippy

                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >

                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/withGurway.png"}
                                />
                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} >{pageContent.WGRTopic1} </h3>
                            {/* <p style={{color:'white'}} >  Convenient Airports Service </p> */}

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} > {pageContent.WGRTopic1Desc} </p>
                        </BackSide>
                    </Flippy>



                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"}
                                />
                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} >{pageContent.WGRTopic2} </h3>

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} >{pageContent.WGRTopic2Desc}</p>
                        </BackSide>
                    </Flippy>



                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >

                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                {/* <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"}/> */}


                                {/* <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0"> */}
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/earning.png"} />
                                {/* </div> */}


                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} >{pageContent.WGRTopic3}</h3>
                            {/* <p style={{color:'white'}} >No Surge Pricing</p> */}

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} >{pageContent.WGRTopic3Desc}</p>
                        </BackSide>
                    </Flippy>



                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >

<div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/withGurway.png"}
                                />
                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} >{pageContent.WGRTopic4}</h3>
                            {/* <p style={{color:'white'}} >No Surge Pricing</p> */}

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} > {pageContent.WGRTopic4Desc} </p>
                        </BackSide>
                    </Flippy>


                </div>

                {/* </div> */}



            </section>


{/* 
            <section>

             <p>carousel section</p>

             <CarouselPage/>

      

            </section> */}



            {/*  */}

            <Step
                stepNumbers={[pageContent.step1, pageContent.step2, pageContent.step3, pageContent.step4, pageContent.step5]}
                stepContents={[pageContent.step1Desc, pageContent.step2Desc, pageContent.step3Desc, pageContent.step4Desc, pageContent.step5Desc]}
                stepImages={[pageContent.step1Image, pageContent.step2Image, pageContent.step3Image, pageContent.step4Image, pageContent.step5Image]}
            />
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{pageContent.slogan6}</h1>
                        <p class="mb-8 leading-relaxed">{pageContent.slogan6Desc}</p>
                        <div class="flex justify-center">
                           

                            <button class="mt-3 text-indigo-500 inline-flex items-center">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                                {pageContent.linkSafetyFeatures}
                            </button>
                        </div>
                    </div>
                    {/*  */}

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{pageContent.cost}</h1>
                        <p class="mb-8 leading-relaxed">{pageContent.costDesc}</p>
                        <div class="flex justify-center">
                          

                            <button class="mt-3 text-indigo-500 inline-flex items-center">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                                {pageContent.costButton2}
                            </button>
                        </div>
                    </div>



                    {/*  */}














                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src={process.env.PUBLIC_URL + "/assets/rider/travelSafety.png"}style={{height:300,width:700}}  />
                    </div>
                </div>
            </section>





            <div>
                <h2 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" style={{ textAlign: 'center' }} > {pageContent.moreOffer} </h2>

                <div class="flex justify-center"  >
                    {/* <Link to='/instant' >
                    <button class=" inline-flex items-center" style={{ backgroundColor:bgColour }}  onMouseEnter={() => setBgColour("#c83f49")}
        onMouseLeave={() => setBgColour("#fafafa")} >
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            class="w-4 h-4 ml-2"
                            viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>

                        {pageContent.moreOffer1BTN}
                        
                    </button>
                    </Link> */}
                    <Link to='/instant' >
                    <Button variant="contained" endIcon={<SendIcon />}>
                    {pageContent.moreOffer1BTN}
      </Button></Link>

                    {/* <Link to='/schedule' >
                    <button class=" inline-flex items-center" style={{ backgroundColor:bgColour1 }}  onMouseEnter={() => setBgColour1("#c83f49")}
        onMouseLeave={() => setBgColour1("#fafafa")}>
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            class="w-4 h-4 ml-2"
                            viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                        {pageContent.moreOffer2BTN}
                    </button>
                    </Link> */}
                    <Link to='/schedule' style={{marginLeft:20}} >
                    <Button variant="contained" endIcon={<SendIcon />}>
                    {pageContent.moreOffer2BTN}
      </Button></Link>


                    {/* <Link to='/hour' >
                    <button class=" inline-flex items-center" style={{backgroundImage:bgColour2==='#c83f49'?bgColour2:"linear-gradient(to right, #4880EC, #019CAD)", backgroundColor:bgColour2,color:bgColour2==='#c83f49'?'white':'black' }}  onMouseEnter={() => setBgColour2("#c83f49")}
        onMouseLeave={() => setBgColour2("#fafafa")}>
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            class="w-4 h-4 ml-2"
                            viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                        {pageContent.moreOffer3BTN}
                    </button>
                    </Link> */}

<Link to='/hour' style={{marginLeft:20}} >
                    <Button variant="contained" endIcon={<SendIcon />}>
                    {pageContent.moreOffer3BTN}
      </Button></Link>
                    
                </div>

            </div>

            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">

                    <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                        {/* <div class="flex w-full justify-center items-end">
                            <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
                                <label for="hero-field" class="leading-7 text-sm text-gray-600">{pageContent.emailPlaceholder}</label>
                                <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded focus:ring-2 focus:ring-indigo-200 focus:bg-transparent border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">{pageContent.signup}</button>
                        </div> */}
                        {/* <p class="text-sm mt-2 text-gray-500 mb-8 w-full">{pageContent.newsLetterHead}</p> */}
                        <div class="flex">
                            <button class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 512 512">
                                    <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                                </svg>
                                <span class="ml-4 flex items-start flex-col leading-none">
                                    <span class="text-xs text-gray-600 mb-1">GET IT ON</span>
                                    <span class="title-font font-medium">Google Play</span>
                                </span>
                            </button>
                            <button class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center ml-4 hover:bg-gray-200 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 305 305">
                                    <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                                    <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                                </svg>
                                <span class="ml-4 flex items-start flex-col leading-none">
                                    <span class="text-xs text-gray-600 mb-1">Download on the</span>
                                    <span class="title-font font-medium">App Store</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}