import React from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';

import Step from '../components/Step'
import Content from '../Content.json'
export default function Home() {
    const pageContent = Content.driver
    return (
        <div>

            <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div
                        class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1
                            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span class="block xl:inline">{pageContent.head1}</span>
                            <br/>
                            <span class="block text-indigo-600 xl:inline">{pageContent.head2}</span>
                            <br/>

                            <span class="block xl:inline">{pageContent.head3}</span>
                        </h1>

                        <h1 class="text-gray-800 text-2xl title-font font-medium mb-4 pt-8">{pageContent.headBody}</h1>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL + "/assets/driver/opperturnity.jpg"}/>
                    </div>
                </div>
            </section>

            <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div
                        class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{pageContent.getMost}</h1>
                        <p class="mb-8 leading-relaxed">{pageContent.makeLot}</p>
                        {/* <div class="flex w-full md:justify-start justify-center items-end">
                            <div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
                                <label for="hero-field" class="leading-7 text-sm text-gray-600">{pageContent.enterMail}</label>
                                <input
                                    type="text"
                                    id="hero-field"
                                    name="hero-field"
                                    class="w-full bg-gray-100 rounded border bg-opacity-50 border-indigo-500 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                            </div>
                            <button
                                class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">{pageContent.applyDrive}</button>
                        </div> */}
                        {/* <p class="text-sm mt-2 text-gray-500 mb-8 w-full">{pageContent.tc}</p> */}

                    </div>

                </div>
            </section>

            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">{pageContent.why}</h1>
                        {/* <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p> */}
                        {/* <div
                            class="flex mx-auto border-2 border-indigo-500 rounded overflow-hidden mt-6">
                            <button class="py-1 px-4 bg-indigo-500 text-white focus:outline-none">Monthly</button>
                            <button class="py-1 px-4 focus:outline-none">Annually</button>
                        </div> */}
                    </div>
                    <div class="flex flex-wrap -m-4">

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/time.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.ownTime}</h1>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.ownTimeDesc}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/bonus.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.bonus}

                                </h1>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.bonusDesc1}
                                </p>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.bonusDesc2}
                                </p>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.bonusDesc3}
                                </p>

                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/bank.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.paid}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.paidDesc}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/help.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.help}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2">
                                    <span
                                        class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            class="w-3 h-3"
                                            viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>{pageContent.helpDesc}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Step
                stepNumbers={pageContent.stepNumbers}
                stepContents={pageContent.stepContents}
                stepImages={pageContent.stepImages}/>

            <section class="w-full h-40 lg:h-64 md:h-40 p-2">
                <img
                    src={process.env.PUBLIC_URL + "/assets/driver/driverWithGurway.jpg"}
                    class="object-fill w-full h-full rounded-lg"
                    alt="hero gurway"/>
            </section>
            
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">{pageContent.howGurwayWorks}</h1>
                    </div>
                    <div class="flex flex-wrap -m-4 justify-center">

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/goOnline.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.howHead1}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.howDesc1}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/accepting.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.howHead2}

                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                  {pageContent.howDesc2}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/direction.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.howHead3}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.howDesc3}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/earning.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.howHead4}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.howDesc4}
                                </p>

                            </div>
                        </div>
                        <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/rating.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.howHead5}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.howDesc5}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">{pageContent.requirements}</h1>
                    </div>
                    <div class="flex flex-wrap -m-4 justify-center">

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/age.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq1}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc1}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/dl.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq2}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc2}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/cic.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq3}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc3}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/registration.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq4}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc4}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/vehicleInspection.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq5}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc5}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/airportPermit.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq6}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc6}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col items-center relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/driver/insuranceCard.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.rq7}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                   {pageContent.desc7}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">

                        Concerns about safety

                        </h1>

                        <div>
                    <p  style={{textAlign:'center'}} >
                        When you drive with Gurway, you become part of our family. Our primary focus is at our driver's safety on the road. 
                        <br/>
                         We're here to help you whenever you need it. At each ride, we take a look at our driver's well-being.
                                </p>
                    </div>

                        

                    </div>

                    <h2 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900" style={{textAlign:'center'}} >

                    Embark on a journey to a life of peace and excitement.


                        </h2>

                    

            <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
                    {/* <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"/> */}
                    <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                        {/* <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Knausgaard typewriter readymade marfa</h1> */}
                        {/* <p class="mb-8 leading-relaxed">Kickstarter biodiesel roof party wayfarers cold-pressed. Palo santo live-edge tumeric scenester copper mug flexitarian. Prism vice offal plaid everyday carry. Gluten-free chia VHS squid listicle artisan.</p> */}
                        {/* <div class="flex w-full justify-center items-end">
        <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
          <label for="hero-field" class="leading-7 text-sm text-gray-600">Placeholder</label>
          <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded focus:ring-2 focus:ring-indigo-200 focus:bg-transparent border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
      </div> */}
                        {/* <p class="text-sm mt-2 text-gray-500 mb-8 w-full">Neutra shabby chic ramps, viral fixie.</p> */}
                        <div class="flex">
                            <button
                                class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    class="w-6 h-6"
                                    viewBox="0 0 512 512">
                                    <path
                                        d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                                </svg>
                                <span class="ml-4 flex items-start flex-col leading-none">
                                    <span class="text-xs text-gray-600 mb-1">GET IT ON</span>
                                    <span class="title-font font-medium">Google Play</span>
                                </span>
                            </button>
                            <button
                                class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center ml-4 hover:bg-gray-200 focus:outline-none">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    class="w-6 h-6"
                                    viewBox="0 0 305 305">
                                    <path
                                        d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                                    <path
                                        d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                                </svg>
                                <span class="ml-4 flex items-start flex-col leading-none">
                                    <span class="text-xs text-gray-600 mb-1">Download on the</span>
                                    <span class="title-font font-medium">App Store</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}