


import React, { useState } from 'react';

const countryCodes = [ { code: '+1', name: 'United States', id: 1 },
{ code: '+44', name: 'United Kingdom', id: 2 },
{ code: '+91', name: 'India', id: 3 },
{ code: '+93', name: 'Afghanistan', id: 4 },
{ code: '+355', name: 'Albania', id: 5 },
{ code: '+213', name: 'Algeria', id: 6 },
{ code: '+1', name: 'American Samoa', id: 7 },
{ code: '+376', name: 'Andorra', id: 8 },
{ code: '+244', name: 'Angola', id: 9 },
{ code: '+1', name: 'Anguilla', id: 10 },
{ code: '+1', name: 'Antigua and Barbuda', id: 11 },
{ code: '+54', name: 'Argentina', id: 12 },
{ code: '+374', name: 'Armenia', id: 13 },
{ code: '+297', name: 'Aruba', id: 14 },
{ code: '+61', name: 'Australia', id: 15 },
{ code: '+43', name: 'Austria', id: 16 },
{ code: '+994', name: 'Azerbaijan', id: 17 },
{ code: '+1', name: 'Bahamas', id: 18 },
{ code: '+973', name: 'Bahrain', id: 19 },
{ code: '+880', name: 'Bangladesh', id: 20 },
{ code: '+1', name: 'Barbados', id: 21 },
{ code: '+375', name: 'Belarus', id: 22 },
{ code: '+32', name: 'Belgium', id: 23 },
{ code: '+501', name: 'Belize', id: 24 },
{ code: '+229', name: 'Benin', id: 25 },
{ code: '+1', name: 'Bermuda', id: 26 },
{ code: '+975', name: 'Bhutan', id: 27 },
{ code: '+591', name: 'Bolivia', id: 28 },
{ code: '+387', name: 'Bosnia and Herzegovina', id: 29 },
{ code: '+267', name: 'Botswana', id: 30 },
{ code: '+55', name: 'Brazil', id: 31 },
{ code: '+246', name: 'British Indian Ocean Territory', id: 32 },
{ code: '+1', name: 'British Virgin Islands', id: 33 },
{ code: '+673', name: 'Brunei', id: 34 },
{ code: '+359', name: 'Bulgaria', id: 35 },
{ code: '+226', name: 'Burkina Faso', id: 36 },
{ code: '+257', name: 'Burundi', id: 37 },
{ code: '+855', name: 'Cambodia', id: 38 },
{ code: '+237', name: 'Cameroon', id: 39 },
{ code: '+1', name: 'Canada', id: 40 },
{ code: '+238', name: 'Cape Verde', id: 41 },
{ code: '+1', name: 'Cayman Islands', id: 42 },
{ code: '+236', name: 'Central African Republic', id: 43 },
{ code: '+235', name: 'Chad', id: 44 },
{ code: '+56', name: 'Chile', id: 45 },
{ code: '+86', name: 'China', id: 46 },
{ code: '+61', name: 'Christmas Island', id: 47 },
{ code: '+61', name: 'Cocos Islands', id: 48 },
{ code: '+57', name: 'Colombia', id: 49 },
{ code: '+269', name: 'Comoros', id: 50 },
{ code: '+682', name: 'Cook Islands', id: 51 },
{ code: '+506', name: 'Costa Rica', id: 52 },
{ code: '+385', name: 'Croatia', id: 53 },
{ code: '+53', name: 'Cuba', id: 54 },
{ code: '+599', name: 'Curaçao', id: 55 },
{ code: '+357', name: 'Cyprus', id: 56 },
{ code: '+420', name: 'Czech Republic', id: 57 },
{ code: '+243',
  name: 'Democratic Republic of the Congo',
  id: 58 },
{ code: '+45', name: 'Denmark', id: 59 },
{ code: '+253', name: 'Djibouti', id: 60 },
{ code: '+1', name: 'Dominica', id: 61 },
{ code: '+1', name: 'Dominican Republic', id: 62 },
{ code: '+593', name: 'Ecuador', id: 63 },
{ code: '+20', name: 'Egypt', id: 64 },
{ code: '+503', name: 'El Salvador', id: 65 },
{ code: '+240', name: 'Equatorial Guinea', id: 66 },
{ code: '+291', name: 'Eritrea', id: 67 },
{ code: '+372', name: 'Estonia', id: 68 },
{ code: '+251', name: 'Ethiopia', id: 69 },
{ code: '+500', name: 'Falkland Islands', id: 70 },
{ code: '+298', name: 'Faroe Islands', id: 71 },
{ code: '+679', name: 'Fiji', id: 72 },
{ code: '+358', name: 'Finland', id: 73 },
{ code: '+33', name: 'France', id: 74 },
{ code: '+689', name: 'French Polynesia', id: 75 },
{ code: '+241', name: 'Gabon', id: 76 },
{ code: '+220', name: 'Gambia', id: 77 },
{ code: '+995', name: 'Georgia', id: 78 },
{ code: '+49', name: 'Germany', id: 79 },
{ code: '+233', name: 'Ghana', id: 80 },
{ code: '+350', name: 'Gibraltar', id: 81 },
{ code: '+30', name: 'Greece', id: 82 },
{ code: '+299', name: 'Greenland', id: 83 },
{ code: '+1', name: 'Grenada', id: 84 },
{ code: '+1', name: 'Guam', id: 85 },
{ code: '+502', name: 'Guatemala', id: 86 },
{ code: '+44', name: 'Guernsey', id: 87 },
{ code: '+224', name: 'Guinea', id: 88 },
{ code: '+245', name: 'Guinea-Bissau', id: 89 },
{ code: '+592', name: 'Guyana', id: 90 },
{ code: '+509', name: 'Haiti', id: 91 },
{ code: '+504', name: 'Honduras', id: 92 },
{ code: '+852', name: 'Hong Kong', id: 93 },
{ code: '+36', name: 'Hungary', id: 94 },
{ code: '+354', name: 'Iceland', id: 95 },
{ code: '+62', name: 'Indonesia', id: 96 },
{ code: '+98', name: 'Iran', id: 97 },
{ code: '+964', name: 'Iraq', id: 98 },
{ code: '+353', name: 'Ireland', id: 99 },
{ code: '+44', name: 'Isle of Man', id: 100 },
{ code: '+972', name: 'Israel', id: 101 },
{ code: '+39', name: 'Italy', id: 102 },
{ code: '+225', name: 'Ivory Coast', id: 103 },
{ code: '+1', name: 'Jamaica', id: 104 },
{ code: '+44', name: 'Jersey', id: 105 },
{ code: '+962', name: 'Jordan', id: 106 },
{ code: '+7', name: 'Kazakhstan', id: 107 },
{ code: '+254', name: 'Kenya', id: 108 },
{ code: '+686', name: 'Kiribati', id: 109 },
{ code: '+383', name: 'Kosovo', id: 110 },
{ code: '+965', name: 'Kuwait', id: 111 },
{ code: '+996', name: 'Kyrgyzstan', id: 112 },
{ code: '+856', name: 'Laos', id: 113 },
{ code: '+371', name: 'Latvia', id: 114 },
{ code: '+961', name: 'Lebanon', id: 115 },
{ code: '+266', name: 'Lesotho', id: 116 },
{ code: '+231', name: 'Liberia', id: 117 },
{ code: '+218', name: 'Libya', id: 118 },
{ code: '+423', name: 'Liechtenstein', id: 119 },
{ code: '+370', name: 'Lithuania', id: 120 },
{ code: '+352', name: 'Luxembourg', id: 121 },
{ code: '+853', name: 'Macau', id: 122 },
{ code: '+389', name: 'Macedonia', id: 123 },
{ code: '+261', name: 'Madagascar', id: 124 },
{ code: '+265', name: 'Malawi', id: 125 },
{ code: '+60', name: 'Malaysia', id: 126 },
{ code: '+960', name: 'Maldives', id: 127 },
{ code: '+223', name: 'Mali', id: 128 },
{ code: '+356', name: 'Malta', id: 129 },
{ code: '+692', name: 'Marshall Islands', id: 130 },
{ code: '+222', name: 'Mauritania', id: 131 },
{ code: '+230', name: 'Mauritius', id: 132 },
{ code: '+262', name: 'Mayotte', id: 133 },
{ code: '+52', name: 'Mexico', id: 134 },
{ code: '+691', name: 'Micronesia', id: 135 },
{ code: '+373', name: 'Moldova', id: 136 },
{ code: '+377', name: 'Monaco', id: 137 },
{ code: '+976', name: 'Mongolia', id: 138 },
{ code: '+382', name: 'Montenegro', id: 139 },
{ code: '+1', name: 'Montserrat', id: 140 },
{ code: '+212', name: 'Morocco', id: 141 },
{ code: '+258', name: 'Mozambique', id: 142 },
{ code: '+95', name: 'Myanmar', id: 143 },
{ code: '+264', name: 'Namibia', id: 144 },
{ code: '+674', name: 'Nauru', id: 145 },
{ code: '+977', name: 'Nepal', id: 146 },
{ code: '+31', name: 'Netherlands', id: 147 },
{ code: '+599', name: 'Netherlands Antilles', id: 148 },
{ code: '+687', name: 'New Caledonia', id: 149 },
{ code: '+64', name: 'New Zealand', id: 150 },
{ code: '+505', name: 'Nicaragua', id: 151 },
{ code: '+227', name: 'Niger', id: 152 },
{ code: '+234', name: 'Nigeria', id: 153 },
{ code: '+683', name: 'Niue', id: 154 },
{ code: '+672', name: 'Norfolk Island', id: 155 } ]

function PhoneNumberInput({ onValidation, onChange }) {
    const [countryCode, setCountryCode] = useState(countryCodes[0].code);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    

    function handleCountryCodeChange(event) {
       // console.log(event.target.value)
        setCountryCode(event.target.value);
    }

    function handlePhoneNumberChange(event) {
        const value = event.target.value;
        const pattern = /^\d{10}$/;
        setPhoneNumber(value);
        onChange(countryCode + value)
        if (!pattern.test(value)) {
            setPhoneNumberError('Please enter a valid 10-digit phone number');
            onValidation(false)
        } else {
            setPhoneNumberError('');
            onValidation(true)
        }
    }

    return (
        <div class="p-2 w-full">
            <div class="relative">
                <label htmlFor="countryCodeSelect" class="leading-7 text-sm text-gray-600">Phone Number:</label>
                <div class="flex flex-row">
                    <select
                        id="countryCodeSelect"
                        class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        value={countryCode}
                        onChange={handleCountryCodeChange}>
                        {countryCodes.map((country,index) => (
                            <option key={index} value={country.id}>
                                {country.name} ({country.code})
                            </option>
                        ))}
                    </select>
                    <input
                        class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                        type="tel"
                        id="phoneNumberInput"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter your phone number"
                    />
                </div>

                {phoneNumberError && <p>{phoneNumberError}</p>}
            </div>

        </div>


        //   <button type="submit">Submit</button> bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out

    );
}
export default PhoneNumberInput;