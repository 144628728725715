import React, { useState } from 'react';

import NumericInput from 'react-numeric-input';

function NumberSelector({min=1,max=20,onChange,label=`Select a number between ${min} and ${max}:`}) {
  const [selectedNumber, setSelectedNumber] = useState(1);

  const handleNumberChange = (event) => {
    // console.log(event)
    setSelectedNumber(event || 1)
   onChange(event || 1)
    // const number = parseInt(event.target.value);
    // if (number >= 1 && number <= 20) {
    //   setSelectedNumber(number);
    //   onChange(number)
    // }
  };

    return (
        <div class="p-2 w-1/2">
            <div class="relative">
                <label htmlFor="number" class="leading-7 text-sm text-gray-600">{label}</label>
                <br />
                <NumericInput
                    class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    type="number"
                    id="number"
                    name="number"
                    min={min}
                    max={max}
                    value={selectedNumber}
                    onInvalid= {(e)=> console.log({oninvalid:e})}
                    onValid = {(e) => console.log({onValid:e})}
                    onChange={handleNumberChange}
                />
            </div>

            {/* <p>You selected: {selectedNumber}</p> */}
        </div>
    );
}

export default NumberSelector;
