import React from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';
import Content from '../Content.json'

export default function Safety() {
    var pageContent = Content.safety
    return (
        <div>
            <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div
                        class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{pageContent.slogan1}</h1>
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{pageContent.slogan2}</h1>

                        <p class="mb-8 leading-relaxed">{pageContent.slogan_desc}</p>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            // src={process.env.PUBLIC_URL+"/assets/safety/main.png"}
                            src={process.env.PUBLIC_URL+"/assets/Safety/main.png"}

                            //process.env.PUBLIC_URL + "/assets/riderPrimary.jpg"
                            />
                    </div>
                </div>
            </section>

            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col">
                        {/* <div class="h-1 bg-gray-200 rounded overflow-hidden">
                            <div class="w-24 h-full bg-indigo-500"></div>
                        </div> */}
                        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                            <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Our Safety Features</h1>
                        </div>
                    </div>

                    <div class="flex flex-wrap -m-4 justify-center">

                        <div class="p-4 xl:w-1/5 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/search.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.f1Head}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.f1Desc}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/5 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/location.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.f2Head}

                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.f2Desc}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/5 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/call.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.f3Head}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.f3Desc}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/5 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/help.jpg"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.f4Head}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.f4Desc}
                                </p>

                            </div>
                        </div>
                        <div class="p-4 xl:w-1/5 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/wellbeing.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.f5Head}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.f5Desc}
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="text-gray-600 body-font">
                <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                    <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">{pageContent.comeToLifeHead}</h1>
                    <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">{pageContent.comeToLifeDesc}</p>
                </div>
                <img
                    src={process.env.PUBLIC_URL + "/assets/Safety/comeToLife.png"}
                    class="object-scale-down w-full h-full rounded-lg"
                    alt="hero gurway"/>
            </section>

            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    
                    <div class="flex flex-wrap -m-4 justify-center">

                        <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/safety.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">{pageContent.box_head1}</h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.box_desc1}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/cleanRide.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.box_head2}

                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.box_desc2}
                                </p>
                            </div>
                        </div>

                        <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
                            <div
                                class="h-full p-6 rounded-lg border-0 flex flex-col relative overflow-hidden">
                                <div class="flex flex-col justify-center items-center ">
                                    <img
                                        class="object-fill object-center rounded h-20 w-20"
                                        alt="hero"
                                        src={process.env.PUBLIC_URL + "/assets/Safety/temp.png"}/>
                                </div>
                                <h1
                                    class="text-center text-3xl text-gray-900 pb-4 mb-4 border-0 border-gray-200 leading-none">
                                    {pageContent.box_head3}
                                </h1>
                                <p class="flex items-center text-gray-600 mb-2 text-center">
                                    {pageContent.box_desc3}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">{pageContent.safety_features_h}</h2>
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{pageContent.safety_features_caption}</h1>
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">{pageContent.safety_features_caption_desc}</p>
                    </div>
                    <div class="flex flex-wrap">

                        <div
                            class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                            <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">{pageContent.safety_features_t1}</h2>
                            <p class="leading-relaxed text-base mb-4">{pageContent.safety_features_body1}</p>
                        </div>

                        <div
                            class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                            <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">{pageContent.safety_features_t2}</h2>
                            <p class="leading-relaxed text-base mb-4">{pageContent.safety_features_body2}</p>
                        </div>

                        <div
                            class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                            <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">{pageContent.safety_features_t3}</h2>
                            <p class="leading-relaxed text-base mb-4">{pageContent.safety_features_body3}</p>
                        </div>

                        <div
                            class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                            <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">{pageContent.safety_features_t4}</h2>
                            <p class="leading-relaxed text-base mb-4">{pageContent.safety_features_body4}</p>
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">

                    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                        <div class="p-4 md:w-1/3 flex">
                            <div
                                class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-6 h-6"
                                    viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div class="flex-grow pl-6">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">{pageContent.box_head1}</h2>
                                <p class="leading-relaxed text-base">{pageContent.box_desc1}</p>
                                <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        class="w-4 h-4 ml-2"
                                        viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>

                        <div class="p-4 md:w-1/3 flex">
                            <div
                                class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-6 h-6"
                                    viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div class="flex-grow pl-6">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">{pageContent.box_head2}</h2>
                                <p class="leading-relaxed text-base">{pageContent.box_desc2}</p>
                                <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        class="w-4 h-4 ml-2"
                                        viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>

                        <div class="p-4 md:w-1/3 flex">
                            <div
                                class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-6 h-6"
                                    viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div class="flex-grow pl-6">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">{pageContent.box_head3}</h2>
                                <p class="leading-relaxed text-base">{pageContent.box_desc3}</p>
                                <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        class="w-4 h-4 ml-2"
                                        viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto flex flex-wrap">
                    <h2
                        class="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">{pageContent.safety_features_t5}</h2>
                    <div class="md:w-3/5 md:pl-6">
                        <p class="leading-relaxed text-base">{pageContent.safety_features_body5}</p>
                        <div class="flex md:mt-4 mt-6">
                            <a class="text-indigo-500 inline-flex items-center ml-4">Learn More
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section> */}

        </div>
    )
}