import React,{useState} from 'react';
import '../App.css';

export default function QuestionAnswer(props){
    const [expanded,setExpanded] = useState(false)

    function handleClick(){
        //console.log('setting ',!expanded)
        setExpanded(!expanded)
    }
    if(props.pointAnswer) return(
        <>
         <button class="ml-4 text-1xl text-indigo-500 tracking-widest font-medium title-font mb-1 pt-4" onClick={handleClick}>{props.question}</button>
         {  expanded && <Points points={props.points}/>}
        </>
    )
    else 
    return(
        <>
        <button class="ml-4 text-1xl text-indigo-500 tracking-widest font-medium title-font mb-1 pt-4" onClick={handleClick}>{props.question}</button>
       { expanded && <p class="ml-6 leading-relaxed text-base indent-8 text-justify">{props.answer}</p>}
        </>
    )
}

function Points(props){
    const {points} = props
    const listItems = points.map((point) =>  <li class="ml-10 leading-relaxed text-base indent-8 text-justify"key={point}>{point}</li>);
    return (
        <>
         <ul class="list-disc" >{listItems}</ul>,
        </>
    )
}

