import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import { auth, logout,checkUser ,getOnewayRidesSuggestions,bookRide} from "../../utils/firebase";
import RideType from '../../components/RidesType'
import BreadCrumb from "../../components/Breadcrumb";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Dashboard() {
  
  const [user, loading] = useAuthState(auth);
  const [email,setEmail] = useState()
  const [org,setOrg] = useState()
  const [loadingOrg,setLoadingOrg]= useState(false)
  const navigate = useNavigate();

  const [suggestions,setSuggestions] = useState()
  const [suggestionsLoading,setSuggestionsLoading] = useState(false)
  const [payload,setPayload] = useState()

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    hourCycle: 'h12'
  };

  //on dashboard load check user register under a org or not
  useEffect(()=>{
      function checkUserAccess() {
            setLoadingOrg(true)
          checkUser({ email })
              .then(res => {
               
                  const { status, org } = res.data
                  switch (status) {
                      case 'FOUND':
                          setOrg(org)
                          setLoadingOrg(false)
                          break;
                      case 'NOT_FOUND':
                          setOrg(null)
                          setLoadingOrg(false)
                          break;
                      default:
                          setOrg(null)
                          setLoadingOrg(false)
                          alert('Unknown status found')
                          break;
                  }
              })
              .catch(error => {
                  console.log({ error })
                  setLoadingOrg(false)
                  alert('Unknown error occurred try again later')
              })

      }
   if(email) checkUserAccess()
  },[email])

  useEffect(() => {
    if (loading) return;
    if(!loading && !user) navigate("/login");
    if(user) setEmail(user.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

 

  async function showRides(data){
    const {payload,step}= data
    payload.booked_by = user.displayName
    payload.booked_by_email = user.email
    setPayload(payload)
    if(step === 'one-way'){
        console.log('getting one way')
        setSuggestionsLoading(true)
        const suggestions = await getOnewayRidesSuggestions(payload)
        // console.log({suggestions,payload})
        setSuggestions(suggestions.data)
        setSuggestionsLoading(false)
        // navigate("/categories",{state:suggestions});
    }
  }

    function CategoryCard({ data, onSelect }) {
        // console.log("categories card", data)
        const { img_url, display_name, estimation, seats_available, category_id,baggage_available } = data

        return <div key={category_id} class="flex flex-col items-center bg-white border rounded-lg shadow-md md:flex-row w-full hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <div class="flex flex-col justify-between p-4 leading-normal">
                <div class="flex flex-row ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{`Max. ${seats_available}`}</p>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                    </svg>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{`Max. ${baggage_available || seats_available - 1}`}</p>
                </div>

                <div class="px-3 py-4 overflow-y-auto rounded ">
                    <ul class="space-y-2">
                        <li>
                            <div class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                {/* <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg> */}
                                <span class="ml-3">Free 60 minutes wait time for airport pickups, 15 mins for all others</span>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                                </svg>

                                {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg> */}
                                <span class="flex-1 ml-3 whitespace-nowrap">Includes Meet & Greet</span>

                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" fill="none"><path fill="currentColor" d="M15.234 20H8.766a2 2 0 0 1-1.985-1.752L6 12l2.072-.69a2 2 0 0 1 1.742.233l1.077.717a2 2 0 0 0 2.218 0l1.077-.717a2 2 0 0 1 1.742-.234L18 12l-.781 6.248A2 2 0 0 1 15.234 20z" /><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.5 4h15M6 12l.781 6.248A2 2 0 0 0 8.766 20h6.468a2 2 0 0 0 1.985-1.752L18 12M6 12l2.072-.69a2 2 0 0 1 1.742.233l1.077.717a2 2 0 0 0 2.218 0l1.077-.717a2 2 0 0 1 1.742-.234L18 12M6 12l-.938-7.5M18 12l.938-7.5" /></svg>

                                {/* <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg> */}
                                <span class="ml-3">Complimentary bottled water</span>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                {/* <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg> */}
                                <span class="ml-3">Free cancellation up until 1 hour before pickup</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <img class="object-scale-down w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={img_url} alt="" />
            <div class="flex flex-col justify-between p-4 leading-normal">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{display_name}</h5>


                <div class="flex items-center justify-between">
                    <span class="text-3xl font-bold text-gray-900 dark:text-white">{`$${estimation}`}</span>

                </div>
                <button
                    onClick={() => onSelect(category_id)}
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Book</button>
            </div>
        </div>;

        // return <div class="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
        //     <a href="#">
        //         <img class="p-8 rounded-t-lg" src={img_url} alt="product image" />
        //     </a>
        //     <div class="px-5 pb-5">
        //         <a href="#">
        //             <h5 class="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{display_name}</h5>
        //         </a>
        //         <div class="flex items-center mt-2.5 mb-5">
        //             <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
        //             <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
        //             <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
        //             <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
        //             <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
        //             <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">5.0</span>
        //         </div>
        //         <div class="flex items-center justify-between">
        //             <span class="text-3xl font-bold text-gray-900 dark:text-white">{`$${estimation}`}</span>
        //             <a href="#" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Add to cart</a>
        //         </div>
        //     </div>
        // </div>;
    }
async function scheduleRide(category_id){
    try {
        setSuggestionsLoading(true)
      const res = await bookRide({_id:suggestions._id,category_id})
    //   console.log({res})
      const {data} = res
      if(data.status === "SUCCESS"){
        alert('Ride booked successfully')
        setSuggestions(null)
        setSuggestionsLoading(false)
      }
      else{
        setSuggestionsLoading(false)
        alert('Unknown status recieved')
      }
    } catch (error) {
        setSuggestionsLoading(false)
        alert('some error occurred try again later')
    }
  }
  function handleBreadCrumbClick(title){
    if(title === "Home"){
        setSuggestions(null)
    }
  }
  return (
    
      <div class="container mx-auto relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl dark:bg-gray-800">
          <div class="flex items-center justify-between w-full mb-8">
              <p class="text-xl font-normal text-gray-800 dark:text-white">
                  {`Book Ride ${org ? org.name :""}`}
              </p>

              <div class="shadow-lg rounded-2xl bg-white dark:bg-gray-800 p-4">
                  <div class="flex-row gap-4 flex justify-center items-center">
                      <div class=" flex flex-col">
                          <span class="text-lg font-medium text-gray-600 dark:text-white">
                              {user ? user.displayName || "" : ""}
                          </span>
                          <span class="text-xs text-gray-400">
                          {user ? user.email || "" : ""}
                          </span>
                      </div>
                      <button type="button" 
                      onClick={()=>logout()}
                      class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                          LOGOUT
                      </button>
                  </div>
              </div>

          </div>
          {
            !suggestions && 
            <div>
            <div class="flex items-start justify-between mb-6 rounded">
                 <span class="p-2 text-white bg-yellow-300 rounded-full dark:text-gray-800">
                     <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                         <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                         </path>
                     </svg>
                 </span>
                 <div class="flex items-center justify-between w-full">
                     <div class="flex flex-col items-start justify-between w-full ml-2 text-sm">
                         <p class="text-gray-700 dark:text-white">
                             Complete your journey with scheduled roundtrips
                         </p>
   
                     </div>
                 </div>
             </div>
             <div class="flex items-start justify-between mb-6 rounded">
                 <span class="p-2 text-white bg-green-400 rounded-full dark:text-gray-800">
                     <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                         <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                         </path>
                     </svg>
                 </span>
                 <div class="flex items-center justify-between w-full">
                     <div class="flex flex-col items-start justify-between w-full ml-2 text-sm">
                         <p class="text-gray-700 dark:text-white">
                             Introducing stress-free roundtrip reservations for airports, events and everyday journeys
                         </p>
   
                     </div>
                 </div>
             </div>
            {!suggestionsLoading && org &&  <RideType  onShowRides={showRides}/>}

            {
                suggestionsLoading && 
                <Skeleton count={10} /> 
            }

            {
                loadingOrg &&
                <Skeleton count={10} /> 
            }
             {!loadingOrg && !org && !loading && <p class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider">
                   Thanks for showing interest in gurway corporate. Your account is not registered with any gurway corporate accounts.
                   <br/>
                   You can contact us at admin@gurway.com
             </p>}
            </div>
          }

          {
            suggestions  && 
            <div class="container mx-auto relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl dark:bg-gray-800">
                      <BreadCrumb buttons={[
                          { title: 'Home', clickable: true },
                          { title: `${payload.pickup.label} >> ${payload.dropoff.label}`, clickable: false },
                          { title: `${ suggestions.suggestions[0].ride_distance.duration.text}`, clickable: false },
                          { title: `${suggestions.suggestions[0].ride_distance.distance.text}`, clickable: false },
                          { title: `${ new Intl.DateTimeFormat('en-US', options).format(payload.datetime)}`, clickable: false }
                      ]} 
                        onClick={handleBreadCrumbClick}
                      />
           
  
           {
            !suggestionsLoading &&   suggestions.suggestions.map(cat => <CategoryCard data={cat} onSelect={(category_id)=>scheduleRide(category_id)}/>)
           }
           {
                suggestionsLoading && 
                <Skeleton count={10} /> 
            }
            
  
      
           
            
            </div>
          }
        
          
      </div>
      
  );
}

export default Dashboard;