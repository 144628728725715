import React, {useEffect, useState} from 'react';

import logo from '../images/driver.png';
import logo1 from '../images/user.png';
import logo2 from '../images/driver1.jpeg'
import logo3 from '../images/ride-label.png'
import '../App.css';





export default function Header() {

  
    let timer
    var [currentImage,
        setCurrentImage] = useState(0)

    var images = [logo, logo1, logo2, logo3]

    useEffect(() => {
        switchImage()
        return () => clearInterval(timer)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function switchImage() {

        timer = !timer && setInterval(() => {

            setCurrentImage(prev => prev < images.length - 1
                ? prev + 1
                : 0)
        }, 2000)

    }
    return (

        <div>
            <footer class="text-gray-600 body-font">
                <div
                    class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div
                        class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
                        <button
                            class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                            <img class="h-8 w-auto sm:h-10" alt="logo" src={images[currentImage]}/>
                            
                        </button>
                        <p class="mt-2 text-sm text-gray-500">Gurway Software Solutions</p>
                    </div>
                    <div
                        class="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
                        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Company</h2>
                            <nav class="list-none mb-10">
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./'>Home</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./safety'>Safety</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./help' >Help</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800"  href='./blog'>Blog</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./career'>Career</a>
                                </li>
                            </nav>
                        </div>
                        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Product</h2>
                            <nav class="list-none mb-10">
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./driver'>Drive</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800"href='./rider' >Ride</a>
                                </li>
                                
                            </nav>
                        </div>
                        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Legal</h2>
                            <nav class="list-none mb-10">
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./privacy'>Privacy Policy</a>
                                </li>
                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./terms'>Terms and Conditions</a>
                                </li>

                                <li>
                                    <a class="text-gray-600 hover:text-gray-800" href='./terms'>Do not sell my info(California)</a>
                                </li>
                                
                            </nav>
                        </div>
                        
                    </div>
                </div>
                <div class="bg-gray-100">
                    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p class="text-gray-500 text-sm text-center sm:text-left">© 2021-22 GURWAY (AJS Luxury Limo. Inc,)
                        </p>
                        <p class="text-gray-500 text-sm text-center sm:text-left" style={{marginLeft:50}} >Contact Info - Contact@gurway.com
                        </p>
                        <span
                            class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <button class="text-gray-500">
                                <svg
                                    fill="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-5 h-5"
                                    viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </button>
                            <button class="ml-3 text-gray-500">
                                <svg
                                    fill="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-5 h-5"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                </svg>
                            </button>
                            <button class="ml-3 text-gray-500">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    class="w-5 h-5"
                                    viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </button>
                            <button class="ml-3 text-gray-500">
                                <svg
                                    fill="currentColor"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="0"
                                    class="w-5 h-5"
                                    viewBox="0 0 24 24">
                                    <path
                                        stroke="none"
                                        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                </svg>
                            </button>
                        </span>
                    </div>
                </div>
            </footer>

        </div>

    )
}

