import React from 'react';
import Button from './Button';

function BreadCrumb({ onValidation, onClick,buttons=[{title:"Home",clickable:true}] }) {


    return (
        <div>
           
            <nav className="rounded-md w-full">
                <ol className="list-reset flex">
                    {buttons.map((b) =>
                        <>
                            <li><Button
                                text={b.title}
                                class= "text-blue-600 hover:text-blue-700" 
                                onClick={() => { onClick(b.title) }} />

                            </li>
                            <li><span class="text-gray-500 mx-2">/</span></li>
                        </>
                    )}


                    {/* <li><Button onClick={()=>{console.log("Home button clicked")}} text={"Home"}/></li>
                    <li><span className="text-gray-500 mx-2">/</span></li>
                    <li><a href="#" className="text-blue-600 hover:text-blue-700">Library</a></li>
                    <li><span className="text-gray-500 mx-2">/</span></li>
                    <li className="text-gray-500">Data</li> */}
                </ol>
            </nav>
        </div>


    );
}
export default BreadCrumb;
