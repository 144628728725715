import React, {useState} from 'react';
import '../App.css';

/**
 * 
 * @param {Object} props stepNumbers Array| stepContents Array |stepImages Array
 * @returns 
 */
export default function Step(props) {

    var [step,
        setStep] = useState(0)
    var {stepNumbers = [
        'STEP 1',
        'STEP 2',
        'STEP 3',
        'STEP 4',
        'STEP 5',
        'STEP 6'
    ],
    stepContents=[
        'Download the Gurway app fromthe Google Play or Apple App Store',
        'Sign up with your phone number',
        'Kindly upload necessary documents',
        'Wait for the completion of document verification and get approval',
        'Give your bank details and Connect your bank account with Gurway',
        'Accept rides to make money'
    ],
    stepImages=[
        "https://dummyimage.com/720x600",
        "https://dummyimage.com/720x600",
        "https://dummyimage.com/720x600",
        "https://dummyimage.com/720x600",
        "https://dummyimage.com/720x600",
        "https://dummyimage.com/720x600",
    ]} = props
    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto flex flex-wrap flex-col">
                <div class="flex mx-auto flex-wrap mb-20">
                    <Header selectedStep={step} stepNames={stepNumbers} onSelect ={(selected) => setStep(selected)}/>
                </div>
                <ContentImage stepNames={stepContents} selectedStep={step} stepImages={stepImages}/>
            </div>
        </section>

    )
}

function Header(props) {
    var selectedStep = props.selectedStep || 0
    var {stepNames} = props
    var normalStyle = "sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-fo" +
            "nt font-medium inline-flex items-center leading-none border-gray-200 hover:text-" +
            "gray-900 tracking-wider"
    var highlightedStyle = "sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-fo" +
            "nt font-medium bg-gray-100 inline-flex items-center leading-none border-indigo-5" +
            "00 text-indigo-500 tracking-wider rounded-t"

    return (stepNames.map((val, index) => (
        <button
            onClick={() => props.onSelect(index)}
            class={index === selectedStep
            ? highlightedStyle
            : normalStyle}>
            <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                class="w-5 h-5 mr-3"
                viewBox="0 0 24 24">
                <path
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>{val}
        </button>
    )))
}

function ContentImage(props) {
    var selectedStep = props.selectedStep || 0
    var {stepNames,stepImages} = props

    return (

        <div>
            <img
                class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded"
                alt={`${selectedStep} step`}
                src={process.env.PUBLIC_URL + stepImages[selectedStep]}/>
            <div class="flex flex-col text-center w-full">
                <h1 class="text-xl font-medium title-font mb-4 text-gray-900">{stepNames[selectedStep]}</h1>
                  {/* <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag
            tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table.
            Franzen you probably haven't heard of them man bun deep jianbing selfies
            heirloom prism food truck ugh squid celiac humblebrag.</p> */}
            </div>
        </div>

    )

}
