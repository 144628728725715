import React, { useState } from "react";

function FullNameInput({ onValidation,onChange }) {
    const [fullName, setFullName] = useState("");
    const [isFullNameValid, setIsFullNameValid] = useState(true);

    const handleFullNameChange = (event) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z\s]*$/; // regex to allow only alphabets and spaces
        setFullName(value);
        setIsFullNameValid(regex.test(value));
        onValidation(regex.test(value))
        onChange(value)
    };

    return (
        <div class="p-2 w-1/2">
            <div class="relative">
                <label htmlFor="fullNameInput" class="leading-7 text-sm text-gray-600">Full Name:</label>
                <input
                    class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                    id="fullNameInput"
                    type="text"
                    value={fullName}
                    onChange={handleFullNameChange}
                    // className={isFullNameValid ? "" : "invalid"}
                />
                {!isFullNameValid && (
                    <div className="error">Please enter a valid full name.</div>
                )}
            </div>

        </div>
    );
}

export default FullNameInput;
