import React, { useState } from 'react';
import '../App.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import DateTimePicker from 'react-datetime-picker';
import Dropdown from 'react-dropdown';
import FullNameInput from './FullNameInput';
import PhoneNumberInput from './PhoneNumberInput';
import NumberSelector from './NumberSelector';
import FlightNumberInput from './FlightNumberInput';
import TRFInput from './TRFInput';
/**
 * 
 * @param {Object} props stepNumbers Array| stepContents Array |stepImages Array
 * @returns 
 */
export default function Step({ onShowRides }) {

    const [step, setStep] = useState(0)
    const stepNumbers = ['One-way', ] //'Roundtrip', 'Hourly'

    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto flex flex-wrap flex-col">
                <div class="flex mx-auto flex-wrap mb-20">
                    <Header selectedStep={step} stepNames={stepNumbers} onSelect={(selected) => setStep(selected)} />
                </div>
                <StepContent selectedStep={step} onShowRidesClicked={(data) =>onShowRides(data)} />
            </div>
        </section>

    )
}

function Header(props) {
    var selectedStep = props.selectedStep || 0
    var { stepNames } = props
    var normalStyle = "sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-fo" +
        "nt font-medium inline-flex items-center leading-none border-gray-200 hover:text-" +
        "gray-900 tracking-wider"
    var highlightedStyle = "sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-fo" +
        "nt font-medium bg-gray-100 inline-flex items-center leading-none border-indigo-5" +
        "00 text-indigo-500 tracking-wider rounded-t"

    return (stepNames.map((val, index) => (
        <button
            onClick={() => props.onSelect(index)}
            class={index === selectedStep
                ? highlightedStyle
                : normalStyle}>
            {val}
        </button>
    )))
}

function StepContent({ selectedStep, onShowRidesClicked }) {
    const [datetime, onChange] = useState(new Date(new Date(new Date(new Date(Date.now() + (2 * 60 * 60 * 1000)).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })))))
    const [pickup, setPickup] = useState()
    const [dropoff, setDropoff] = useState()

    const [rdatetime, onChangeR] = useState(new Date(new Date(new Date(new Date(Date.now() + (2 * 60 * 60 * 1000)).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })))))
    const [rpickup, setRPickup] = useState()
    const [rdropoff, setRDropoff] = useState()

    const [notes, setNotes] = useState("")

    const [flightNo,setFlightNo] = useState("")
    const [flightNoValid,setFlightNoValid] = useState(true)

    const [refCode,setRefCode] = useState("")
    const [refCodeValid,setRefCodeValid] = useState(true)

    const [hours,setHours] = useState()

    const [passengerName,setPassengerName] = useState()
    const [isPassengerNameValid,setIsPassengerNameValid] = useState(false)

    const [phoneNumber,setPhoneNumber]= useState()
    const [phoneNumberValid,setPhoneNumberValid] = useState(false)

    const [passengers,setPassengers] = useState(1)
    const [baggage,setBaggage]= useState(1)

    const options = []
    for (let i = 2; i <= 24; i++) {
        options.push({ value: i, label: `${i} Hours` })
    }

    const defaultOption = options[0];
    var step = selectedStep || 0

 

    function showRidesForOneway(){
        
        let day = datetime.getDate();
        let month = datetime.getMonth() + 1; // getMonth() returns a zero-based value, so we add 1
        let year = datetime.getFullYear();
        let hour = datetime.getHours();
        let minutes = datetime.getMinutes();
        let date = {day,month,year,hour,minutes}

        const payload = {pickup,dropoff,datetime,notes,refCode,flightNo,date,passengerName,phoneNumber,passengers,baggage}

        if (!pickup) {
            alert("Pickup address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!dropoff) {
            alert("Dropoff address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!datetime){
            alert("Pickup Date & Time : Pick up date & time must not be in the past")
            return
        }
        if(!flightNoValid){
            alert("Enter a valid flight number")
            return
        }

        if(!refCodeValid){
            alert("Enter a valid reference code")
            return
        }

        if(!isPassengerNameValid){
            alert("Enter a valid passenger name")
            return
        }
        if(!phoneNumberValid){
            alert("Enter a valid phone number with country code")
            return
        }
        onShowRidesClicked({step:'one-way',payload})
    }

    function showRidesForRoundTrip(){
        
        const payload = {pickup,dropoff,datetime,rpickup,rdropoff,rdatetime,notes,refCode,flightNo}
        if (!pickup) {
            alert("Pickup address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!dropoff) {
            alert("Dropoff address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!datetime){
            alert("Pickup Date & Time : Pick up date & time must not be in the past")
        }

        if (!rpickup) {
            alert("Return Pickup address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!rdropoff) {
            alert("Return Dropoff address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!rdatetime){
            alert("Return Pickup Date & Time : Pick up date & time must not be in the past")
            return
        }
        onShowRidesClicked({step:'round-trip',payload})
        // console.log({step:'round-trip',payload})
    }

    function showRidesForHourly(){
        
        const payload = {pickup,datetime,notes,hours,refCode,flightNo}
        if (!pickup) {
            alert("Pickup address : Please enter a complete address or choose one of the suggested locations.")
            return
        }
        if(!datetime){
            alert("Pickup Date & Time : Pick up date & time must not be in the past")
            return
        }
        onShowRidesClicked({step:'hourly',payload})
        // console.log({step:'hourly',payload})
    }

    switch (step) {
        case 0:
            return (
                <section class="text-gray-600 body-font relative">

                    <div class="container px-5 py-24 mx-auto">

                        <div class="lg:w-1/2 md:w-2/3 mx-auto">
                            <div class="flex flex-wrap -m-2">
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: pickup,
                                                onChange: setPickup
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I'
                                            type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="email" class="leading-7 text-sm text-gray-600">Dropoff address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: dropoff,
                                                onChange: setDropoff
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup Date & Time </label>
                                        <DateTimePicker 
                                        minDate={new Date(new Date(new Date(new Date(Date.now() + (2 * 60 * 60 * 1000)).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))))}
                                        maxDate={new Date(new Date(Date.now() + (90 * 24 * 60 * 60 * 1000)).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))}
                                        value={datetime} 
                                        onChange={onChange} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <FullNameInput onValidation={setIsPassengerNameValid} onChange={setPassengerName} />
                                <PhoneNumberInput onValidation={setPhoneNumberValid} onChange={setPhoneNumber} />
                                <NumberSelector min={1} max={10} onChange={setPassengers} label={`Select number of passengers`} />
                                <NumberSelector min={1} max={10} onChange={setBaggage} label={`Select number of baggage`} />
                                <FlightNumberInput onValidation={setFlightNoValid} onChange={setFlightNo} />
                                <TRFInput onValidation={setRefCodeValid} onChange={setRefCode} />
                                {/* <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Flight number </label>
                                        <input type="text" value={flightNo} onChange={(e) => setFlightNo(e.target.value)} placeholder={"e.g LH 201, U24547, BA2490"} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div> */}
                                {/* <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Reference code</label>
                                        <input type="text" value={refCode} onChange={e => setRefCode(e.target.value)}  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div> */}
                                <div class="p-2 w-full">
                                    <div class="relative">
                                        <label for="message" class="leading-7 text-sm text-gray-600">Notes</label>
                                        <textarea
                                            value={notes}
                                            onChange={(event) => setNotes(event.target.value)}
                                            id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                    </div>
                                </div>
                                <div class="p-2 w-full">
                                    <button onClick={showRidesForOneway}
                                        class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Show rides</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        case 1:
            return (
                <section class="text-gray-600 body-font relative">

                    <div class="container px-5 py-24 mx-auto">

                        <div class="lg:w-1/2 md:w-2/3 mx-auto">
                            <div class="flex flex-wrap -m-2">
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: pickup,
                                                onChange: setPickup
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="email" class="leading-7 text-sm text-gray-600">Dropoff address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: dropoff,
                                                onChange: setDropoff
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup Date & Time </label>
                                        <DateTimePicker value={datetime} onChange={onChange} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>

                            </div>

                            <div class="flex flex-wrap -m-2">
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Return Pickup address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: rpickup,
                                                onChange: setRPickup
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="email" class="leading-7 text-sm text-gray-600">Return Dropoff address</label>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: rdropoff,
                                                onChange: setRDropoff
                                            }}
                                            apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Return Pickup Date & Time </label>
                                        <DateTimePicker value={rdatetime} onChange={onChangeR} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>

                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Flight number </label>
                                        <input type="text" value={flightNo} onChange={setFlightNo} placeholder={"e.g LH 201, U24547, BA2490"} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Reference code</label>
                                        <input type="text" value={refCode} onChange={setRefCode}  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                
                                <div class="p-2 w-full">
                                    <div class="relative">
                                        <label for="message" class="leading-7 text-sm text-gray-600">Notes</label>
                                        <textarea
                                            value={notes}
                                            onChange={(event) => setNotes(event.target.value)}
                                            id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                    </div>
                                </div>


                                <div class="p-2 w-full">
                                    <button 
                                    onClick={showRidesForRoundTrip}
                                    class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Show rides</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        case 2:
            return (
                <section class="text-gray-600 body-font relative">

                    <div class="container px-5 py-24 mx-auto">

                        <div class="lg:w-1/2 md:w-2/3 mx-auto">
                            <div class="flex flex-wrap -m-2">


                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup address</label>
                                        <GooglePlacesAutocomplete 
                                        selectProps={{
                                            value: pickup,
                                            onChange: setPickup
                                        }}
                                        apiKey='AIzaSyCKEXMz7gZuTs_zUZKxw7GClEj4fbhNJ0I' type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Duration</label>
                                        <Dropdown options={options} value={hours || defaultOption} onChange={setHours} placeholder="Duration" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>

                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Pickup Date & Time </label>
                                        <DateTimePicker value={datetime} onChange={onChange} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>

                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Flight number </label>
                                        <input type="text" value={flightNo} onChange={setFlightNo} placeholder={"e.g LH 201, U24547, BA2490"} class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-1/2">
                                    <div class="relative">
                                        <label for="name" class="leading-7 text-sm text-gray-600">Reference code</label>
                                        <input type="text" value={refCode} onChange={setRefCode}  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div class="p-2 w-full">
                                    <div class="relative">
                                        <label for="message" class="leading-7 text-sm text-gray-600">Notes</label>
                                        <textarea
                                            value={notes}
                                            onChange={(event) => setNotes(event.target.value)}
                                            id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                    </div>
                                </div>
                                <div class="p-2 w-full">
                                    <button 
                                    onClick={showRidesForHourly}
                                    class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Show rides</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        default:
           return( <div></div>)
    }
}
