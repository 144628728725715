import React, { useState } from 'react';

function FlightNumberInput({onValidation,onChange}) {
  const [flightNumbers, setFlightNumbers] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleFlightNumberChange = (event) => {
    const inputValue = event.target.value;
    const flightNumberRegex = /^.{0,150}$/;
    const isValidInput = flightNumberRegex.test(inputValue);
    setIsValid(isValidInput);
    onValidation(isValidInput)
    if (isValidInput) {
      setFlightNumbers(inputValue);
      onChange(inputValue)
    }
  };

  return (
      <div class="p-2 w-1/2">
          <div class="relative">
              <label htmlFor="flightNumbers" class="leading-7 text-sm text-gray-600">Enter flight numbers:</label>
              <input
                  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  type="text"
                  id="flightNumbers"
                  name="flightNumbers"
                  value={flightNumbers}
                  onChange={handleFlightNumberChange}
              />
              {!isValid && <p style={{ color: 'red' }}>Invalid flight numbers input</p>}
              {/* <p>You entered: {flightNumbers}</p> */}
          </div>
      </div>
  );
}

export default FlightNumberInput;
