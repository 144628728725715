import React from 'react';
import './blog.css'
import BlogImg from './../blogImg.jpeg'

var blogs=[
    {
        mainTitle:'5 Necessary Reasons Why Airport Pick-Up and Drop-Off Service is Necessary',
        mainTitleDesc:'When you arrive in a new country that you are unfamiliar with, you may face numerous challenges such as language issues, unfamiliar places, getting lost, mugged, jet lag, missing a flight and so on. Gurway is now providing Airport curbside pick-up and drop-off services to let you relax a little bit more.',
        mainHelper:'Some of the benefits of using our service are as follows:',
        title1:'While you are traveling alone',
        title1Desc:"If traveling alone makes you nervous, you don't have to bother about such issues now. If you reserve your airport ride ahead of time, you will be sure of your safety and comfort. Begin to enjoy yourself without worry, and don't miss out on the thrill of traveling alone!",
        title2:'Traveling to a new location',
        title2Desc:'Arriving in a new region or migrating to a new location can be stressful, especially if you are unfamiliar with the area. You may be concerned about your safety and worried about how you will cope. Book a Gurway airport pick-up and drop-off service to prevent all of these concerns! Then our driver will assure your safety while driving you to your destination. You would not be lonely in the future!',
        title3:"When you're carrying a lot of luggage",
        title3Desc:'It can be tough to carry bulky luggage that is too weight to lift. If you arrange an airport pick-up and drop service, our driver will meet you at the specific place and assist you with your bags. Moving heavy luggage is a simple problem to tackle, and you can relax and enjoy the voyage!',
        title4:'Dealing with Language Issues',
        title4Desc:"Do you intend to travel to the location without knowing the local language? Don't be panic! You do have a remedy for a problem like this. To be sure you're in good hands, book your ride with Gurway ahead of time, and we will welcome you by a friendly and trustworthy person. We will give directions to our drivers as to where they should pick you up and drop you off. So sit back and prepare for an exciting ride!",
        title5:'When you anticipate someone picking you up and dropping you off',
        title5Desc:"Is it impossible for a friend or relative to pick you up from the airport? That's perfectly OK. You are no longer reliant on anyone. Get your phone out and download the Gurway ride app to schedule a ride to your pick-up and drop-off service. It is preferable to make a reservation as soon as possible. You will be able to relax and de-stress as a result.",
        title5Desc1:'Prepare for the ride ahead of time to get the most out of it! Avoid all strain on your journey to make it more memorable! Find the Gurway airport pick-up and drop-off services right now and book the ride as soon as possible!'

    }
]


function BlogSite(){


    return(
        <div>
            <div className='blogMainTitle' >{blogs[0].mainTitle}</div>
            <div className='blogMainTitleDesc' >{blogs[0].mainTitleDesc}</div>


            <img src={BlogImg} alt='blog' className='blogImgStyle' />

            <div className='blogMainTitleDesc' >{blogs[0].mainHelper}</div>

            <div className='pointsStyle' >

                <div className='pointsTitle' > 1.{blogs[0].title1} </div>
                <div className='pointsTitleDesc' > {blogs[0].title1Desc} </div>

                <div className='pointsTitle' > 2.{blogs[0].title2} </div>
                <div className='pointsTitleDesc' > {blogs[0].title2Desc} </div>

                <div className='pointsTitle' > 3.{blogs[0].title3} </div>
                <div className='pointsTitleDesc' > {blogs[0].title3Desc} </div>

                <div className='pointsTitle' > 4.{blogs[0].title4} </div>
                <div className='pointsTitleDesc' > {blogs[0].title4Desc} </div>

                <div className='pointsTitle' > 5.{blogs[0].title5} </div>
                <div className='pointsTitleDesc' > {blogs[0].title5Desc} </div>
                <div className='pointsTitleDesc' > {blogs[0].title5Desc1} </div>

                <p style={{marginTop:15,textDecoration:'underline',color:'blue'}} >#Airport</p>
                <p style={{marginTop:5,textDecoration:'underline',color:'blue'}} >#Pickup , #Drop</p>



            </div>

        </div>
    )


}

export default BlogSite