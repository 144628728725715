import React from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';
import Content from '../Content.json'
import QuestionHead from '../components/QuestionHead';
import QuestionAnswer from '../components/QuestionAnswer';

export default function Rider() {
    var pageContent = Content.help
    return (
        <div>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="lg:w-4/5 mx-auto flex flex-wrap">
                        <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">

                            <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">
                                {pageContent.slogan1}
                                <br/>
                                <span>
                                    {pageContent.slogan2}</span>
                                <br/>
                                <span>
                                    {pageContent.slogan3}</span>
                            </h1>

                        </div>
                        <img
                            alt="rider welcome"
                            class="lg:w-1/2 w-full lg:h-auto h-64 object-fill object-center rounded"
                            src={process.env.PUBLIC_URL + "/assets/riderPrimary.jpg"}/>
                    </div>
                </div>

            </section>

            <section class="text-gray-600 body-font">
                <div class="container flex flex-wrap px-5 py-24 mx-auto items-center">

                    <div class="md:w-2/3 md:pr-12 md:py-8 mb-10 md:mb-0 pb-10 ">
                        <QuestionHead text={pageContent.head1}/>
                        <QuestionHead text={pageContent.questionHead1}/>
                        
                        <QuestionAnswer question={pageContent.q1} answer={pageContent.ans1}/>
                        
                        <QuestionAnswer question={pageContent.q2} answer={pageContent.ans2}/>

                        <QuestionAnswer question={pageContent.q3} answer={pageContent.ans3}/>

                        <QuestionAnswer question={pageContent.q4} answer={pageContent.ans4}/>

                        <QuestionHead text={pageContent.head2}/>
                        <QuestionAnswer question={pageContent.q5} answer={pageContent.ans5}/>
                        <QuestionAnswer question={pageContent.q6} answer={pageContent.ans6}/>
                        <QuestionAnswer question={pageContent.q7} answer={pageContent.ans7}/>
                        <QuestionAnswer question={pageContent.q8} answer={pageContent.ans8}/>
                        <QuestionAnswer question={pageContent.q9} answer={pageContent.ans9}/>
                        <QuestionAnswer question={pageContent.q10} answer={pageContent.ans10}/>
                        <QuestionAnswer question={pageContent.q11} answer={pageContent.ans11}/>

                        <QuestionHead text={pageContent.head3}/>
                        <QuestionAnswer question={pageContent.q12} answer={pageContent.ans12}/>
                        <QuestionAnswer question={pageContent.q13} answer={pageContent.ans13}/>

                        <QuestionHead text={pageContent.head4}/>
                        <QuestionAnswer question={pageContent.q14} answer={pageContent.ans14}/>
                        <QuestionAnswer question={pageContent.q15} answer={pageContent.ans15}/>
                        <QuestionAnswer question={pageContent.q16} answer={pageContent.ans16}/>
                        <QuestionAnswer question={pageContent.q17} answer={pageContent.ans17}/>

                        <QuestionHead text={pageContent.head5}/>
                        <QuestionAnswer question={pageContent.q18} answer={pageContent.ans18}/>
                        <QuestionAnswer question={pageContent.q19} points={[pageContent['ans19-1'],pageContent['ans19-2'],pageContent['ans19-3'],pageContent['ans19-4'],pageContent['ans19-5'],pageContent['ans19-6'],pageContent['ans19-7']]} pointAnswer={true}/>

                        <QuestionAnswer question={pageContent.q20} answer={pageContent.ans20}/>
                        <QuestionAnswer question={pageContent.q21} answer={pageContent.ans21}/>
                        <QuestionAnswer question={pageContent.q22} answer={pageContent.ans22}/>
                    </div>
                    
                </div>
            </section>

            

        </div>
    )
}