import React from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';

export default function Home() {

    return (
        <div>

<section class="text-gray-700 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="md:flex">

        <div class="mt-4 md:mt-0 md:ml-6">
          <div class="uppercase tracking-wide text-sm text-indigo-600 font-bold">Gurway Terms and Conditions</div>
          <button class="block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline">Last updated:
            2020-10-22</button>
          <p class="mt-2 text-gray-600">

          <ol>
            <li>Introduction
              Welcome to Gurway (“Company”, “we”, “our”, “us”)!
              These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at gurway.com
              (together or individually “Service”) operated by AJS Luxury Limo Inc.
              Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard, and
              disclose information that results from your use of our web pages and mobile applications.
              Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that
              you have read and understood Agreements, and agree to be bound by them.
              If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please
              let us know by emailing at info@gurway.com so we can try to find a solution. These Terms apply to all
              visitors and application users, users, and others who wish to access or use Service.</li>
            <li>Communications
              By using our Service, you agree to subscribe to newsletters, marketing or promotional materials, and other
              information we may send. However, you may opt-out of receiving any, or all, of these communications from
              us by following the unsubscribe link or by emailing at info@gurway.com.</li>
            <li>Contests, Sweepstakes, and Promotions
              Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service
              may be governed by rules that are separate from these Terms of Service. If you participate in any
              Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion
              conflict with these Terms of Service, Promotion rules will apply.</li>
            <li>Content
              The content found on or through this Service are the property of Gurway/AJS Luxury Limo Inc or used with
              permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content,
              whether in whole or in part, for commercial purposes or personal gain, without express advance written
              permission from us.</li>
            <li>Prohibited Uses
              You may use Service only for lawful purposes and under Terms. You agree not to use Service:
              0.1. In any way that violates any applicable national or international law or regulation.
              0.2. to exploit, harm, or attempting to exploit or harm minors in any way by exposing them to
              inappropriate content or otherwise.
              0.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk
              mail”, “chain letter,” “spam,” or any other similar solicitation.
              0.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other
              person or entity.
              0.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening,
              fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or
              activity.
              0.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or
              which, as determined by us, may harm or offend Company or users of Service or expose them to
              liability.
              Additionally, you agree not to:
              0.1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with
              any other party’s use of Service, including their ability to engage in real-time activities through
              Service.
              0.2. Use any robot, spider, or other automatic devices, process, or means to access Service for any
              purpose, including monitoring or copying any of the material on Service.
              0.3. Use any manual process to monitor or copy any of the material on Service or for any other
              unauthorized purpose without our prior written consent.
              0.4. Use any device, software, or routine that interferes with the proper working of Service.
              0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or
              technologically harmful.
              0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the
              server on which Service is stored, or any server, computer, or database connected to Service.
              0.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
              0.8. Take any action that may damage or falsify Company rating.
              0.9. Otherwise attempt to interfere with the proper working of Service.</li>
            <li>Analytics
              We may use third-party Service Providers to monitor and analyze the use of our Service.</li>
            <li>No Use By Minors
              Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing
              or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the
              full authority, right, and capacity to enter into this agreement and abide by all of the terms and
              conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the
              access and usage of Service.</li>
            <li>Intellectual Property
              Service and its original content (excluding Content provided by users), features, and functionality are
              and will remain the exclusive property of Gurway and its licensors. Service is protected by copyright,
              trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any
              product or service without the prior written consent of Gurway.</li>
            <li>Copyright Policy
              We respect the intellectual property rights of others. It is our policy to respond to any claim that
              Content posted on Service infringes on the copyright or other intellectual property rights
              (“Infringement”) of any person or entity.
              If you are a copyright owner or authorized on behalf of one, and you believe that the copyrighted work has
              been copied in a way that constitutes copyright infringement, please submit your claim via email to
              info@gurway.com, with the subject line: “Copyright Infringement” and include in your claim a detailed
              description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright
              Infringement Claims”
              You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or
              bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
            </li>
            <li>DMCA Notice and Procedure for Copyright Infringement Claims
              You may submit a notification according to the Digital Millennium Copyright Act (DMCA) by providing our
              Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further
              detail):
              0.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the
              copyright’s interest;
              0.2. a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web
              page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
              0.3. identification of the URL or other specific location on Service where the material that you claim is
              infringing is located;
              0.4. your address, telephone number, and email address;
              0.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law;
              0.6. a statement by you, made under penalty of perjury, that the above information in your notice is
              accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
              You can contact our Copyright Agent via email at info@gurway.com</li>
            <li>Error Reporting and Feedback
              You may provide us either directly at info@gurway.com or via third-party sites and tools with information
              and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other
              matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain,
              acquire or assert any intellectual property right or other rights, title or interest in or to the
              Feedback; (ii) Company may have developed ideas similar to the Feedback; (iii) Feedback does not contain
              confidential information or proprietary information from you or any third party, and (iv) Company is not
              under any obligation of confidentiality concerning the Feedback. In the event the transfer of the
              ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its
              affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and
              perpetual right to use (including copy, modify, create derivative works, publish, distribute and
              commercialize) Feedback in any manner and for any purpose.</li>
            <li>Links To Other Web Sites
              Our Service may contain links to third-party websites or services that are not owned or controlled by
              Gurway.
              Gurway has no control over and assumes no responsibility for the content, privacy policies, or practices
              of any third-party websites or services. We do not warrant the offerings of any of these
              entities/individuals or their websites.
              13.Gurway Rides
              Background check for drivers are mandatory failed to provide background check report or uploading of false
              reports is prohibited. 
              Minimum Insurence 7,50,00$
              All driver must allow disabled persons with service dog this is mandatory any refusal from the driver side
              and complain filed by the user will result in deactivating the account right away without any notice
              Any road accident driver/owner used own commercial insurance we are not responsible any loss and damage
              for driver or rider during the ride if any accident and rider is injured driver/owner right way provide
              own commercial insurance Gurway / AJS Luxury limo Inc is not responsible any loss or damage
              All vehicle accept minimum 2015 year (Model)
              when the user cancels the ride after 5 minutes ofter order the ride is fee off 10$ cancellation fee
              All fares charges according to choose off category time of ride, the distance of the ride.
              Airport drivers must have Airport permits if they work any airport LA County -orange county
              Gurway fees each ride 25% for the total fare 
              requirement of vehicles 
              Commercial Registration
              Commercial Insurence
              Driving license must be a 2year driving record
              TCP Permit
              Airport permit (Optional)
              Background checks
              Vehicle Photo
              Vehicle Inspection
              only accept Black colors SUV, sedan cars</li>
          </ol>
          <p>YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
            DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
            CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR
            SERVICES THAT YOU VISIT.</p>
          <ol>
            <li>Disclaimer Of Warranty
              THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THIRD-PARTYOWEBSITESR SERVICES, OR
              THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE with
              THATHIRD-PARTYOWEBSITESERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
              RISK.
              NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
              TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
              LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
              SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
              RELIABLE, ERROR-FREE, OR UNINTERRUPconcerningTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
              MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR
              ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
              PARTICULAR PURPOSE.
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </li>
            <li>Limitation Of Liability
              EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS
              FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
              ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
              APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
              NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
              WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
              VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY
              HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
              LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
              SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT
              ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</li>
            <li>Termination
              We may terminate or suspend your account and bar access to Service immediately, without prior notice or
              liability, under our sole discretion, for any reason whatsoever and without limitation, including but not
              limited to a breach of Terms.
              If you wish to terminate your account, you may simply discontinue using Service.
              All provisions of Terms which by their nature should survive termination shall survive termination,
              including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
              liability.</li>
            <li>Governing Law
              These Terms shall be governed and construed following the laws of California State(United States), which
              governing law applies to the agreement without regard to its conflict of law provisions.
              Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
              rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
              provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
              regarding our Service and supersede and replace any prior agreements we might have had between us
              regarding Service.</li>
            <li>Changes To Service
              We reserve the right to withdraw or amend our Service, and any service or material we provide via Service,
              in our sole discretion without notice. We will not be liable if for any reason all or any part of Service
              is unavailable at any time or for any period. From time to time, we may restrict access to some parts of
              Service, or the entire Service, to users, including registered users.</li>
            <li>Amendments To Terms
              We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to
              review these Terms periodically.
              Your continued use of the Platform following the posting of revised Terms means that you accept and agree
              to the changes. You are expected to check this page frequently so you are aware of any changes, as they
              are binding on you.
              By continuing to access or use our Service after any revisions become effective, you agree to be bound by
              the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</li>
            
            <li>Waiver And Severability
              No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing
              waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to
              assert a right or provision under Terms shall not constitute a waiver of such right or provision.
              If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
              illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum
              extent such that the remaining provisions of Terms will continue in full force and effect.</li>
            <li>Acknowledgement
              BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
              SERVICE AND AGREE TO BE BOUND BY THEM.</li>
            <li>Contact Us
              Please send your feedback, comments, requests for technical support by email: info@gurway.com.</li>
          </ol>


          </p>
        </div>
      </div>
    </div>
  </section>

        </div>
    )
}