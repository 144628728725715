import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

 import { getFunctions,connectFunctionsEmulator, httpsCallable } from 'firebase/functions'; // eslint-disable-line no-unused-vars



const firebaseConfig = {

    apiKey: "AIzaSyAIV48nTPrVDbJAX5kw6pBChxQys8Kpp34",
  
    authDomain: "gurway-v2-test.firebaseapp.com",
  
    databaseURL: "https://gurway-v2-test.firebaseio.com",
  
    projectId: "gurway-v2-test",
  
    storageBucket: "gurway-v2-test.appspot.com",
  
    messagingSenderId: "735061474284",
  
    appId: "1:735061474284:web:9b7c8cfa1632d903e5dec9",
  
    measurementId: "G-BPY53L0QFK"
  
  };
  

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app)
// connectFunctionsEmulator(functions,"localhost",5001)

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password,event) => {
  // console.log('login called',email,[password,event])
  event.preventDefault()
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password,e) => {
  e.preventDefault()
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    // console.log('add user with ', {
    //     uid: user.uid,
    //     name,
    //     authProvider: "local",
    //     email,
    //   })
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email,e) => {
  e.preventDefault()
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const checkUser = async (user) => {
  const checkWebUser = httpsCallable(functions, 'checkWebUser')
  try {
    const res = await checkWebUser(user)
    return res
  } catch (error) {
    throw error
  }
}

const getOnewayRidesSuggestions = async (payload) =>{
  const fun = httpsCallable(functions,'getOnewayRidesSuggestions')
  return fun(payload)
}

const bookRide = async (payload) =>{
  const fun = httpsCallable(functions,'bookRide')
  return fun(payload)
}

export {
  auth,
  db,
  analytics,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  checkUser,
  getOnewayRidesSuggestions,
  bookRide
};