import React, { useEffect, useRef } from 'react';
import '../App.css';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
// import ReactCardFlip from 'react-card-flip';

import Flippy, { FrontSide, BackSide } from 'react-flippy';





export default function Home() {



    var lottieRef1 = useRef()





    useEffect(() => {



        lottieRef1
            .current
            .addEventListener('load', function (e) {
                create({
                    mode: 'scroll',
                    player: '#firstLottie1',
                    actions: [

                        {
                            visibility: [0, 0.3],
                            type: 'stop',
                            frames: [0],
                        },
                        {
                            visibility: [0.3, 1],
                            type: 'seek',
                            frames: [0, 171],
                        },
                    ]
                })
            })




    }, [])

    return (
        <div  >
            <div>
                <main
                    class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                    <div class="sm:text-center lg:text-left">
                        <h1
                            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span class="block xl:inline">Snap!</span>
                            <br />
                            <span class="block text-indigo-600 xl:inline">Crackle!</span>
                            <br />
                            <span class="block xl:inline">Jump in.</span>
                            <br />
                            <span class="block text-indigo-600 xl:inline">Join with Gurway</span>
                        </h1>
                        <p
                            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            Welcome to our next-generation mobility platform, designed to help businesses manage all of
                            their ground transportation in one location. Enhance the best services at your fingertips.
                        </p>
                        {/* <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div class="rounded-md shadow">
                                <a
                                    href="http://onelink.to/wkqej5"
                                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                                    Apply to drive
                                </a>
                            </div>
                            <div class="mt-3 sm:mt-0 sm:ml-3">
                                <a
                                    href="http://onelink.to/wkqej5"
                                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                                    Sing up to ride
                                </a>
                            </div>
                        </div> */}
                    </div>
                </main>
            </div>
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                {/* <img
                    class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-auto"
                    src="https://images.unsplash.com/photo-1600320254374-ce2d293c324e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                    alt=""/> */}
                <lottie-player
                    class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-auto"
                    ref={lottieRef1}
                    id="firstLottie1"
                    // controls={false}
                    mode="normal"
                    src="https://assets10.lottiefiles.com/temp/lf20_lCFkPL.json"
                ></lottie-player>

            </div>

            <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img
                            class="object-cover object-center rounded"
                            alt="hero192"
                            // src={require('./../assets/Home/withGurway.png')}
                            src={process.env.PUBLIC_URL + '/assets/Home/withGurway.png'}
                        />

                    </div>
                    <div
                        class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1
                            class="space-x-2  text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span class="block text-indigo-600 xl:inline">Gurway Drive</span>
                            {/* 
                            <span class="block text-indigo-600 xl:inline">with</span>

                            <span class="block xl:inline">Gurway</span> */}

                        </h1>
                        <p
                            class="mt-3 text-base text-gray-800 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            One Goal, One Passion.
                            <br />
                            <span class="block xl:inline">Make Your Schedule.</span>
                            <br />
                            <span class="block xl:inline">Gain at Your Comfort.</span>
                            <br />
                            <span class="block xl:inline">Just the Best</span>
                        </p>

                        <p
                            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            Make a flexible schedule to spend more time with your family and friends. It’s a perfect
                            movement to take control of your life, engage with your community, and make some additional
                            money.
                        </p>

                        <div class="flex border-0 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"} />
                            </div>
                            <div class="flex-grow">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Flexible Time</h2>
                                <p class="leading-relaxed text-base">Make the world a better place to become your own boss, utilize each moment of your life
                                    and drive at your comfort time.</p>

                            </div>
                        </div>
                        <div class="flex border-0 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/earning.png"} />
                            </div>
                            <div class="flex-grow">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Consistent Earnings</h2>
                                <p class="leading-relaxed text-base">Maximize your profits, earn income, keep your tips, and use in-app tools. Get more bonuses like:</p>
                                <div class="p-4  w-full">
                                    <nav
                                        class="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5">
                                        <button>
                                            <span
                                                class="bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                                <svg
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="3"
                                                    class="w-3 h-3"
                                                    viewBox="0 0 24 24">
                                                    <path d="M20 6L9 17l-5-5"></path>
                                                </svg>
                                            </span>Short distance bonus
                                        </button>
                                        <button>
                                            <span
                                                class="bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                                <svg
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="3"
                                                    class="w-3 h-3"
                                                    viewBox="0 0 24 24">
                                                    <path d="M20 6L9 17l-5-5"></path>
                                                </svg>
                                            </span>Peak hour bonus
                                        </button>
                                        <button>
                                            <span
                                                class="bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                                <svg
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="3"
                                                    class="w-3 h-3"
                                                    viewBox="0 0 24 24">
                                                    <path d="M20 6L9 17l-5-5"></path>
                                                </svg>
                                            </span>Airport pickup bonus
                                        </button>
                                    </nav>
                                </div>
                            </div>

                        </div>
                        {/* <div class="flex lg:flex-row md:flex-col">
                            <button
                                class="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">

                                <span class="ml-4 flex items-start flex-col leading-none">

                                    <span class="title-font font-medium">Learn More</span>
                                </span>
                            </button>
                            <button
                                class="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">

                                <span class="ml-4 flex items-start flex-col leading-none">

                                    <span class="title-font font-medium">Subscribe</span>
                                </span>
                            </button>
                        </div> */}

                    </div>
                </div>
            </section>

            {/* ridewith gurway */}


            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div>
                        <main
                            class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div class="sm:text-center lg:text-left">
                                <h1
                                    class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                    <span class="block text-indigo-600 xl:inline">Gurway </span>

                                    <span class="block xl:inline">Ride</span>

                                </h1>
                                <p
                                    class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Up, Up and Away with Gurway! </p>

                                <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0" >  Get ready for a ride that is safer, more comfortable, and puts a city at your fingertips. </p>


                                <div class="p-4  w-full">
                                    <nav
                                        class="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5">
                                        <button>
                                            <span
                                                class=" text-indigo-500 w-10 h-10 mr-2  inline-flex items-center justify-center">
                                                <img
                                                    class="object-cover object-center rounded"
                                                    alt="hero"
                                                    src={process.env.PUBLIC_URL + "/assets/Home/instant.jpg"} />
                                            </span>Get assured rides
                                        </button>

                                        <button>
                                            <span
                                                class=" text-indigo-500 w-10 h-10 mr-2  inline-flex items-center justify-center">
                                                <img
                                                    class="object-cover object-center rounded"
                                                    alt="hero"
                                                    src={process.env.PUBLIC_URL + "/assets/Home/schedule.jpg"} />
                                            </span>Get instant rides
                                        </button>


                                        <button>
                                            <span
                                                class=" text-indigo-500 w-10 h-10 mr-2  inline-flex items-center justify-center">
                                                <img
                                                    class="object-cover object-center rounded"
                                                    alt="hero"
                                                    src={process.env.PUBLIC_URL + "/assets/Home/instant.jpg"} />
                                            </span>Get scheduled ride
                                        </button>


                                    </nav>
                                </div>

                                {/* <div class="flex lg:flex-row md:flex-col">
                                    <button
                                        class="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">

                                        <span class="ml-4 flex items-start flex-col leading-none ">

                                            <span class="title-font font-medium">Schedule a Ride</span>
                                        </span>
                                    </button>


                                    <button style={{ marginLeft: 30 }}
                                        class="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">

                                        <span class="ml-4 flex items-start flex-col leading-none ">

                                            <span class="title-font font-medium">Learn More</span>
                                        </span>
                                    </button>


                                </div> */}

                            </div>


                        </main>

                    </div>

                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 p-2 " >
                        <img class="object-cover object-center rounded" alt="hero" src={process.env.PUBLIC_URL + "/assets/Home/family.png"} />
                    </div>
                </div>
            </section>


            {/*Airport service  */}

            {/* <section class="text-gray-600 body-font">
                <div
                    class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL+"/assets/Home/withGurway.png"}
                            
                            />
                    
                    </div>
                    <div
                        class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1
                            class="space-x-2  text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span class="block text-indigo-600 xl:inline">Convenient Airports Service</span>

                        </h1>
                       

                        <p
                            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                          Provides hassle-free airport pickup and drop-off service that is convenient, smooth, and on time.

                        </p>

                        <div class="flex border-0 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"}/>
                            </div>
                            <div class="flex-grow">
                              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Safety</h2>
                              <p class="leading-relaxed text-base">Every driver will pass through a thorough background check, and each vehicle will undergo a routine car inspection.</p>
                              
                            </div>
                        </div>
                        <div class="flex border-0 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL + "/assets/Home/earning.png"}/>
                            </div>
                            <div class="flex-grow">
                              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">No Surge Pricing</h2>
                              <p class="leading-relaxed text-base">Our prices never vary, even when our competitors boost their pricing during peak hours.</p>
                               
                            </div>
                            
                        </div>
                        <div class="flex lg:flex-row md:flex-col">
                          
                        </div>

                    </div>
                </div> m2pfrRcuBFXR7XazWVwG
            </section> */}




            <section className='text-gray-600 body-font' >

                <h1
                    class="space-x-2  text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl" style={{ textAlign: 'center' }} >
                    <span class="block text-indigo-600 xl:inline"  >Convenient Airport Service's</span>

                </h1>

                <div className='homeFloat' >

                    <Flippy

                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80,marginTop:15 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >

                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/withGurway.png"}
                                />
                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} > Convenient Airports Service  </h3>
                            {/* <p style={{color:'white'}} >  Convenient Airports Service </p> */}

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} >  Provides hassle-free airport pickup and drop-off service that is convenient, smooth, and on time. </p>
                        </BackSide>
                    </Flippy>



                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80,marginTop:15}} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >
                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"}
                                />
                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} > Safety </h3>

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} > Every driver will pass through a thorough background check, and each vehicle will undergo a routine car inspection.</p>
                        </BackSide>
                    </Flippy>



                    <Flippy
                        flipOnHover={true} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '250px', height: '300px', marginLeft: 80,marginTop:15 }} /// these are optional style, it is not necessary
                    >
                        <FrontSide
                            style={{
                                backgroundColor: '#41669d',
                            }}
                        >

                            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0" style={{ marginLeft: '35%', marginTop: '35%' }}>
                                {/* <img
                            class="object-cover object-center rounded"
                            alt="hero"
                            src={process.env.PUBLIC_URL + "/assets/Home/flexible.png"}/> */}


                                {/* <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0"> */}
                                <img
                                    class="object-cover object-center rounded"
                                    alt="hero"
                                    src={process.env.PUBLIC_URL + "/assets/Home/earning.png"} />
                                {/* </div> */}


                            </div>
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: 25, textAlign: 'center', marginTop: '15%' }} >No Surge Pricing </h3>
                            {/* <p style={{color:'white'}} >No Surge Pricing</p> */}

                        </FrontSide>
                        <BackSide
                            style={{ backgroundColor: '#175852' }}>
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginTop: 50 }} >Our prices never vary, even when our competitors boost their pricing during peak hours.</p>
                        </BackSide>
                    </Flippy>


                </div>

                {/* </div> */}



            </section>



            {/*  */}

            {/* Categories */}
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col">
                        {/* <div class="h-1 bg-gray-200 rounded overflow-hidden">
                            <div class="w-24 h-full bg-indigo-500"></div>
                        </div> */}
                        <h1
                            class="space-x-1  text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span class="block xl:inline">Gurway</span>

                            <span class="block text-indigo-600 xl:inline">Vehicle</span>

                            <span class="block xl:inline">Categories</span>

                        </h1>
                        <p
                            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            We have bought you three vehicle categories to get you where you need to go. Select the vehicle according to your requirements to schedule the ride. Book Gurway Black if you need basic service requirements. If you need a luxury vehicle service, then book Gurway LUX.
                        </p>
                        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                            <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0"></p>
                        </div>
                    </div>
                    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                        <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                            <div class="rounded-lg h-64 overflow-hidden">
                                <img
                                    alt="content"
                                    class="object-fill object-center h-40 w-80"
                                    src={process.env.PUBLIC_URL + "/assets/Home/black.png"} />
                                <h2
                                    class="space-x-2  text-2xl  font-extrabold text-gray-600 sm:text-3xl md:text-4xl text-lg font-medium  text-black-600 mt-3">Gurway BLACK</h2>

                            </div>


                        </div>
                        <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                            <div class="rounded-lg h-64 overflow-hidden">
                                <img
                                    alt="content"
                                    class="object-fill object-center h-40 w-80"
                                    src={process.env.PUBLIC_URL + "/assets/Home/suv.png"} />
                                <h2
                                    class="space-x-2  text-2xl  font-extrabold text-gray-600 sm:text-3xl md:text-4xl text-lg font-medium  text-black-600 mt-3">Gurway SUV</h2>
                            </div>


                        </div>
                        <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                            <div class="rounded-lg h-64 overflow-hidden">
                                <img
                                    alt="content"
                                    class="object-fill object-center h-40 w-80"
                                    src={process.env.PUBLIC_URL + "/assets/Home/glux.png"} />
                                <h2
                                    class="space-x-2  text-2xl  font-extrabold text-gray-600 sm:text-3xl md:text-4xl text-lg font-medium  text-black-600 mt-3">Gurway LUXURY (LUX)</h2>
                            </div>


                        </div>
                    </div>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }} >Drive-in with joy, happiness, safety from our ride to yours</p>
                </div>
            </section>
        </div>
    )
}