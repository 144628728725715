import React, { useEffect } from 'react';
// import {Switch, Route, useHistory} from "react-router-dom";
import { Route, Routes, useNavigate } from "react-router-dom";
import './App.css';
import '@lottiefiles/lottie-player';
import { auth } from "./utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// import Header from './components/Header'
import Home from './pages/Home'
import Driver from './pages/Driver'
import Safety from './pages/Safety';
import Rider from './pages/Rider'
import Footer from './components/Footer'
import TC from './pages/TC'
import Privacy from './pages/Privacy'
import Help from './pages/Help'
import Book from './pages/Auth/Login';
import Reset from './pages/Auth/Reset'
import Dashboard from './pages/Book/Dashboard'
import Register from './pages/Auth/Register'
import Categories from './pages/Book/Categories';

import HourlyRidePage from './pages/HourlyRidePage';
import ScheduleRidePage from './pages/ScheduleRidePage';
import InstantRidePage from './pages/InstantRidePage';
import HeaderCheck from './components/newHeader'
import myGa from './myGa';
import ReactPixel from 'react-facebook-pixel';

import BlogSite from './../src/pages/blog'

import GetApp from './../src/pages/GetApp'


function App() {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate()

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    ReactPixel.init('1390265214780773', {}, options);

    useEffect(() => {
        myGa();
    }, []);

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) {
           
            navigate('/dashboard')
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loading]);


    return (
        // <Router>
            <div class="relative bg-white overflow-hidden">
                <div class="max-w-7xl mx-auto">
                    <div
                        class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

                        <HeaderCheck />

                    </div>
                </div>

                <Routes>

                    <Route exact path="/" element={<Home />} />


                    <Route path="/driver" element={<Driver />} />

                    <Route path="/safety" element={<Safety />} />

                    <Route exact path="/rider" element={<Rider />} />

                    <Route exact path="/hour" element={<HourlyRidePage />} />

                    <Route exact path="/schedule" element={<ScheduleRidePage />} />

                    <Route exact path="/instant" element={<InstantRidePage />} />

                    <Route exact path="/blog" element={<BlogSite />} />

                    <Route exact path="/download/rider" element={<GetApp />} />

                    <Route path="/terms" element={<TC />} />

                    <Route path="/privacy" element={<Privacy />} />

                    <Route path="/help" element={<Help />} />

                    <Route path="/login" element={<Book />} />

                    <Route path="/reset" element={<Reset />} />

                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/register" element={<Register />} />
                    <Route path='/categories' element={<Categories />} />


                </Routes>


                <Footer />
            </div>
        // </Router>

    );
}

export default App;
